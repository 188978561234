/*Custom*/

/* // //////////////////////////////
// Globals
// ////////////////////////////// */

body, body * {
  box-sizing: border-box;
  max-width: 100%;
  overflow-wrap: break-word;
  overflow-x: hidden;
}

h1 {
  font-size: 2rem;
  font-weight: 300;
}

main {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

div.container {
  width: 400px;
  max-width:  100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

a.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba( 0, 0, 0, .3 );
  color: rgba( 0, 0, 0, .8 );
  text-decoration: none;
  font-size: 1.5rem;
  padding: .5rem 1.1rem .5rem 1rem;
  margin-top:  1rem;
}

a img {
  height: 50px;
  width: 50px;
  padding-right: 20px;
}


.stretchBackground {
  position: absolute;
  width: 120%;
  min-width: 1920px;
  opacity: .1;
  z-index: -1;
}

.container {
  padding:  2rem;
  background: rgba( 255, 255, 255, 1 );
  box-shadow: 0px 0 5px 5px rgb(0 0 0 / 10%);
  text-align: center;
}

input[type=number] {
  text-align: center;
  padding:  .5rem 1rem;
}

#traits {
  padding: 0;
  width: 100%;
}
#traits li {
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  padding:  .2rem 0;
}

h1, p, label {
  padding: .2rem .5rem;
}

/*Loading spinner*/
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.loading * {
  margin: 2rem 0;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid black;
  border-color: black transparent black transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
